.all-maquinas {
	display: flex;
	flex-wrap: wrap;
	padding: $module 0;
	.maquina-card {
		@include items-grid(4, $module);
	}
}
.edit-maquina {
	width: 100%;
	display: flex;
	margin-bottom: calc(#{$module} * 1);
	padding: calc(#{$module} * 1) 0;
	.button-input-group {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: $module;
		input {
			width: 90%;
		}
		.my-btn {
			min-width: 10%;
		}
	}
	.button-file-group {
		margin-bottom: $module;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.file-input {
			margin-bottom: 0;
			width: 90%;
			background-color: map-get($theme-colors, 'light-grey');
		}
		.my-btn {
			min-width: 10%;
		}
	}

	.left-side,
	.right-side {
		display: flex;
		flex-direction: column;
	}
	.left-side {
		width: 49%;
		margin-right: 2%;
		.features {
			.each-feat {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 10px;
				background-color: map-get($theme-colors, 'light-grey');
				margin-bottom: calc(#{$module} / 6);
				svg {
					align-self: flex-end;
					cursor: pointer;
					width: 15px;
					height: 15px;
				}
			}
		}
		.main-image {
			width: 100px;
			height: 100px;
			overflow: hidden;
			background-color: map-get($theme-colors, 'light-grey');
			position: relative;
			img {
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}
	.right-side {
		width: 49%;
		.gallery {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: calc(#{$module} / 2);
			.each-picture {
				@include items-grid(5, calc(#{$module} / 6));
				height: 100px;
				overflow: hidden;
				background-color: map-get($theme-colors, 'light-grey');
				position: relative;
				img {
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
				.close-icon {
					position: absolute;
					top: 0;
					right: 0;
					cursor: pointer;
					width: 15px;
					height: 15px;
					color: white;
				}
			}
		}
	}
}
