.dash-nav {
	display: flex;
	left: 0;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100vh;
	position: relative;
	padding: $module;
	padding-left: calc(#{$module} / 2);
	figure {
		width: 80px;
	}
	svg {
		width: 100%;
		height: 50px;
		margin: 0 auto;
		padding: calc(#{$module} / 4);
		color: map-get($theme-colors, 'ds-back');
		&:hover {
			text-decoration: none;
			color: map-get($theme-colors, 'ds-focus');
		}
		&.active {
			background-color: map-get($theme-colors, 'ds-focus');
			border-radius: 6px;
			&:hover {
				color: map-get($theme-colors, 'ds-main');
			}
		}
	}

	ul {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		li {
			padding: calc(#{$module} / 3) 0;
			width: 100%;
			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-decoration: none;
				position: relative;
				span {
					position: absolute;
					right: 0;
					display: none;
					color: white;
					height: 100%;
					background-color: map-get($theme-colors, 'ds-focus');
					width: 100%;
					right: -100%;
					line-height: 50px;
					z-index: 2;
					text-align: center;
					border-radius: 5px;
				}
			}
			&:hover {
				a {
					span {
						display: block;
					}
				}
			}
		}
	}
	.btn-group {
		width: 100%;
		display: flex;
		flex-direction: column;
		> * {
			width: 100%;
			margin-top: calc(#{$module} / 3);
		}
		.fix-size {
			width: 42px !important;
		}

		small {
			color: map-get($theme-colors, 'ds-back');
			text-align: center;
		}
	}
}
