.maquina-card {
	@include items-grid(4, $module);
	cursor: pointer;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: $module;
	border-radius: 20px;
	cursor: inherit;
	box-shadow: 0px 0px 5px 0px transparentize($color: map-get($theme-colors, 'ds-main'), $amount: 0.9);
	&:hover {
		box-shadow: 0px 0px 8px 0px transparentize($color: map-get($theme-colors, 'ds-main'), $amount: 0.7);
	}
	h6 {
		font-weight: 600;
		margin-bottom: 4px;
	}
	p {
		color: map-get($theme-colors, 'ds-btn-danger');
		@include font-line-height(12px);
	}
	.maquina-card-body {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
		figure {
			width: 100%;
			height: 200px;
			overflow: hidden;
			margin: 0;
			img {
				height: 100%;
				object-position: center;
				object-fit: contain;
			}
		}
		.button-group {
			width: 100%;
			display: flex;
			align-items: center;
			align-self: flex-end;
			button {
				min-width: 60px;
				margin-top: calc(#{$module} / 3);
				margin-right: 4px;
			}
		}
	}
}
