*[class^='my-btn'] {
	max-width: 200px;
	&:focus,
	&:active {
		outline: 0;
		box-shadow: none;
	}
}

.my-btn {
	font-weight: $text-semibold;
	font-size: $font-sm;
	background-color: #52b3d9;
	border: none;
	color: map-get($theme-colors, 'ds-back');
	border-radius: 5px;
	text-transform: uppercase;
	padding: 6px 4px;

	&.right {
		margin-left: auto !important;
	}

	&:hover {
		color: map-get($theme-colors, 'ds-back');
		text-decoration: none;
		background-color: map-get($theme-colors, 'ds-focus');
	}
	&.secondary {
		background-color: map-get($theme-colors, 'ds-btn-secondary');
		&:hover {
			background-color: map-get($theme-colors, 'ds-focus');
		}
	}
	&.third {
		background-color: map-get($theme-colors, 'ds-btn-third');
		&:hover {
			background-color: map-get($theme-colors, 'ds-focus');
		}
	}
	&.danger {
		background-color: map-get($theme-colors, 'primary');
		&:hover {
			background-color: map-get($theme-colors, 'ds-focus');
		}
	}
	&.mini {
		font-size: 10px;
		padding: calc(#{$module} / 4) calc(#{$module} / 3);
	}
	&.underlined {
		font-size: 10px;
		background-color: transparent;
		color: map-get($theme-colors, 'primary');
		padding: 0;
		border: none;
	}
}

.my-btn-back {
	color: map-get($theme-colors, 'primary');
	text-transform: uppercase;
	font-size: $font-xs;

	&::before {
		content: '\f323';
		font-family: $fontawesome;
		color: map-get($theme-colors, 'primary');
		margin-right: calc(#{$module} / 6);
	}
}

.my-btn-more {
	color: map-get($theme-colors, 'buttons');
	text-transform: uppercase;
	font-size: $font-xs;

	&::after {
		content: '\f324';
		font-family: $fontawesome;
		color: map-get($theme-colors, 'buttons');
		margin-left: calc(#{$module} / 6);
	}
}

.btn-group {
	button {
		margin-left: 6px;
	}
}
.title-btn {
	display: flex;
	align-items: baseline;
	margin-bottom: 12px;
	svg {
		width: 12px;
	}
}
